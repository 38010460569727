import {createApp} from 'vue';
import App from './App.vue';
import {createI18n} from 'vue-i18n';
import messages from './locales/text.yaml';
import VueLogger from 'vuejs3-logger';

// Load Styles

import css from 'bundle-text:../dist/index.981dc3ef.css';

const style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);


const thrInit = function() {
  const appElements = document.querySelectorAll('.sts-tierhaltungsrechner');
  console.log(appElements)

  appElements.forEach((instance) => {
    // Default Config
    const tierhaltungsrechnerDefaultConfig = {
      locale: 'de_CH',
      enableLogs: false,
      preselectCategory: false,
      preselectAnimal: false,
      style: 'full',
      historyMode: 'internal',
    };

    let thrConfig = {};

    // Read config from global object tierhaltungsrechnerConfig.
    // Deprecated config method, but still implemented for compatibility.
    /* global tierhaltungsrechnerConfig */
    if (typeof tierhaltungsrechnerConfig === 'object') {
      thrConfig = {
        ...tierhaltungsrechnerDefaultConfig,
        ...tierhaltungsrechnerConfig,
      };
    } else {
      thrConfig = tierhaltungsrechnerDefaultConfig;
    }

    // Read config from data attributes in the DOM element.
    if (typeof instance.dataset === 'object') {
      thrConfig = {
        ...thrConfig,
        ...instance.dataset,
      };
    }

    console.log(thrConfig);

    // Logging config
    const loggingOptions = {
      isEnabled: thrConfig.enableLogs,
      logLevel: 'debug',
      stringifyArguments: true,
      showLogLevel: true,
      showMethodName: false,
      separator: '|',
      showConsoleColors: true,
    };

    // I18N
    const i18n = createI18n({
      locale: thrConfig.locale,
      fallbackLocale: tierhaltungsrechnerDefaultConfig.locale,
      messages,
    });

    // Initialize App
    const app = createApp(App, {thrConfig: thrConfig});

    app.use(i18n);
    app.use(VueLogger, loggingOptions);

    app.mount(instance);
  });

  // Class names depending on width
  function trWidthClasses() {
    appElements.forEach((instance) => {
      instance.classList.remove('max1400', 'max884', 'max756', 'max540');

      if (instance.offsetWidth <= 1400) {
        instance.classList.add('max1400');
      }
      if (instance.offsetWidth <= 884) {
        instance.classList.add('max884');
      }
      if (instance.offsetWidth <= 756) {
        instance.classList.add('max756');
      }
      if (instance.offsetWidth <= 540) {
        instance.classList.add('max540');
      }

      //new ResizeObserver(trWidthClasses).observe(instance);
    });
  }

  // Events to set class names
  window.addEventListener('load', () => {
    trWidthClasses();
  });

  document.addEventListener('DOMContentLoaded', () => {
    trWidthClasses();
  });

/*

// Load Analytics
  function dynamicallyLoadScript(url) {
    var script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-K34PW3LPX1';

    document.head.appendChild(script);
  }

// Load a script
  function loadScript(url, callback) {
    var head = document.head;
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

// Bind the event to the callback function.
    script.onreadystatechange = callback;
    script.onload = callback;

// Load script
    head.appendChild(script);
  }

// Start Analytics
  function runAnalytics() {
    window.dataLayer = window.dataLayer || [];

    window.gtagTR = function() {
      window.dataLayer.push(arguments);
    };

    window.gtagTR('js', new Date());

    window.gtagTR('config', 'G-K34PW3LPX1');
  }

  loadScript('https://www.googletagmanager.com/gtag/js?id=G-K34PW3LPX1',
      runAnalytics);

*/
};

thrInit();
